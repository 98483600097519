declare const lifeVineApiUrl: string;
declare var DateFormatter: any;

interface Window {
    app;
    controllers: WindowControllers;
    lifeVine: LifeVine;
}

interface JQuery {
    modal: Function;
}


interface WindowControllers {
    account: Account;
    adminDashboard: AdminDashboard;
    bookings: Bookings;
    clients: Clients;
    criteria: Criteria;
    emails: Emails;
    session: Session;
    settings: Settings;
    sitters: Sitters;
    sitterDashboard: SitterDashboard;
    users: Users;
}

function getFormData($form): any {
    let data = {};

    function setCheckboxData(name, checked, value) {
        if (name.slice(-2) === '[]') {
            let newName = name.slice(0, -2);
            if (data[newName] === undefined) {
                data[newName] = [];
            }
            if (checked) {
                data[newName].push(value);
            }
        } else {
            data[name] = checked;
        }
    }

    $form.find('[name]').each((idx, el) => {
        let $el = $(el);
        let name = $el.prop('name');
        if ($el.is('input[type="checkbox"]')) {
            setCheckboxData(name, $el.prop('checked'), $el.val());
        } else if ($el.is('input[type="radio"]')) {
            if ($el.prop('checked')) {
                data[name] = $el.val();
            }
        } else {
            data[name] = $el.val();
        }
    });
    return data;
}

function setFormData($form, data) {
    $form.find('[name]').each((idx, el) => {
        let $el = $(el);
        let name = $el.prop('name');
        let propName = name;

        let arr = false;
        if (name.slice(-2) === '[]') {
            arr = true;
            propName = name.slice(0, -2);
        }

        if (!data.hasOwnProperty(propName) || (arr && !Array.isArray(data[propName]))) {
            return;
        }

        if ($el.is('input[type="checkbox"]')) {
            if (arr) {
                jQuery.each(data[propName], (idx, value) => {
                    if ($el.is(`[value="${value}"]`)) {
                        $el.prop('checked', true);
                    }
                });
            } else {
                $el.prop('checked', data[propName]);
            }
        } else if ($el.is('input[type="date"')) {
            $el.data('value', data[propName]);
        } else if ($el.is('input[type="radio"]')) {
            if ($el.is(`[value="${data[propName]}"]`)) {
                $el.prop('checked', true);
            }
        } else {
            $el.val(data[propName]);
        }
    });
}


interface Date {
    setYmdHis(ymdhis: string): number;
    toYmd(): string;
    toYmdHis(): string;
}
Date.prototype.setYmdHis = function (ymdhis: string) {
    // moment
    // return moment(ymdhis).toDate();
    let parts = ymdhis.split(' ');
    let dateParts = parts[0].split('-');
    this.setFullYear(dateParts[0], parseInt(dateParts[1], 10) - 1, dateParts[2]);
    let timeParts = parts[1].split(':');
    this.setHours(timeParts[0], timeParts[1], timeParts[2], 0);
    return this.valueOf();
};
Date.prototype.toYmd = function () {
    let m = ('0' + (this.getMonth() + 1)).slice(-2);
    let d = ('0' + this.getDate()).slice(-2);
    let Y = this.getFullYear();
    return `${Y}-${m}-${d}`;
};
Date.prototype.toYmdHis = function () {
    let H = ('0' + this.getHours()).slice(-2);
    let i = ('0' + this.getMinutes()).slice(-2);
    let s = ('0' + this.getSeconds()).slice(-2);
    return this.toYmd() + ` ${H}:${i}:${s}`;
};